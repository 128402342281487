import axios from "axios";

// const baseUrl = "http://localhost:5004";
// const emailBaseUrl = "http://localhost:3002";
const baseUrl = "";
const emailBaseUrl = "/mail";

let api = {};

const createGlobalAxios = () => {
  api = axios.create({
    baseURL: `${baseUrl}/api`,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export { baseUrl, api, createGlobalAxios, emailBaseUrl };
